<template>
  <div class="page-container" v-loading="loading">
    <page-header></page-header>
    <page-table ref="table" show-paginate :get-data="deUserReviewPage" :fields="fields" :search-model="searchModel"
                show-selection :item-actions="actions" @onActionClick="handleItemActionClick">
      <template slot="search-items">
        <el-form-item label="" prop="generatorNo">
          <el-input v-model="searchModel.generatorNo" clearable placeholder="发电户号"></el-input>
        </el-form-item>
        <el-form-item label="" prop="state">
          <el-select v-model="searchModel.state" filterable clearable placeholder="用户状态">
            <el-option v-for="(label, value) in stateMap" :key="label" :label="label" :value="value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="" prop="developersId">
          <el-select v-model="searchModel.developersId" filterable clearable placeholder="开发商名称">
            <el-option
                v-for="item in developer_options"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
      </template>
      <template slot="page-actions">
        <el-button @click="handleAdd" size="mini" type="primary">新建</el-button>
<!--       <el-button @click="onUploadSelected" size="mini" type="primary">批量上传</el-button>-->
<!--        <el-button @click="onUploadAll" size="mini" type="primary">一键上传</el-button>-->
      </template>
    </page-table>
    <el-dialog
        title="划转明细"
        :visible.sync="transfer_dialog_visible"
        append-to-body
        :close-on-click-modal="false" width="35%" custom-class="min-w-750">
      <div v-if="transfer_data" style="width: 100%;box-sizing: border-box;padding: 20px;">
        <el-tabs v-model="transfer_year" class="demo-tabs">
          <el-tab-pane v-for="year in transfer_years" :key="year" :label="year" :name="year"></el-tab-pane>
        </el-tabs>
        <div style="padding: 15px;text-align: center">共{{
            transfer_data[transfer_year].list.length
          }}期&nbsp;&nbsp;&nbsp;&nbsp;划转总计：{{ transfer_data[transfer_year].sum }}元
        </div>
        <el-table
            border
            :data="transfer_data[transfer_year].list"
            style="width: 100%">
          <el-table-column
              align="center"
              prop="date"
              label="还款日">
          </el-table-column>
          <el-table-column
              align="center"
              prop="money"
              label="还款金额（元）">
          </el-table-column>
        </el-table>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="transfer_dialog_visible = false">关 闭</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader"
import PageTable from "@/components/PageTable"
import {
  deUserReviewPage,
  deUserRepaymentPost,
  deUserRepaymentPostAll,
  developerAll,
  seeTransferInfo
} from "@/api/common"

const stateMap = {
  '00': '未授权',
  '01': '预授权',
  '02': '已授权',
  '03': '授权结束',
  '04': '失败',
  '05': '授权暂停',
  '06': '授权中止',
  '07': '待上传',
  '08': '已上传',
  '09': '上传失败',
  '10': '无效'
}

export default {
  name: "DeUsers",
  data() {
    return {
      loading: false,
      searchModel: {
        state: '',
        generatorNo: '',
        developersId: '',
      },
      deUserReviewPage: deUserReviewPage,
      fields: [
        {
          label: '开发商名称',
          width: 180,
          render({developers},h) {
            return h('span', developers ? developers.name : '')
          }
        },
        {label: '农户姓名', key: 'name', width:110},
        {label: '身份证号', key: 'idNo', width: 180},
        {label: '发电户号', key: 'generatorNo', width: 120},
        {label: '省份', key: 'province', width: 90},
        {label: '并网日期', key: 'signTime', width: 180},
        {label: '组建块数', key: 'size', width: 100},
        {
          label: '租金类型', width: 100,
          render: (item, h) => {
            return h("span", ['通用型', '10+15型', '5+20型'][item.configure.type])
          }
        },
        {
          label: '租金价格(￥)', width: 120,
          render: (item, h) => {
            return h('div', item.configure.type == 0 ? (item.configure.rent / 100).toFixed(2) : (item.configure.rent1 / 100).toFixed(2) + ',' + (item.configure.rent2 / 100).toFixed(2))
          }
        },
        {label: '理论划转总金额(￥)', key: 'hzMoney',width: 150},
        {label: '每年期数', key: 'periods', width: 100},
      ],
      actions: [
        {action: 'transferInfo', label: '划转明细', type: 'primary'},
        {action: 'transferEdit', label: '更新', type: 'primary'},
        {action: 'transferUpload', label: '上传', type: 'primary'},
      ],
      stateMap: stateMap,
      developer_options: [],
      transfer_data: null,
      transfer_years: [],
      transfer_year: '',
      transfer_dialog_visible: false
    }
  },
  components: {
    PageHeader,
    PageTable
  },
  created() {

  },
  mounted() {
    this.getDeveloperOptions()
  },
  activated() {
    this.$refs.table && this.$refs.table.loadData()
  },
  methods: {
    getDeveloperOptions() {
      let that = this
      developerAll().then(res => {
        that.developer_options = res
      })
    },
    handleItemActionClick(action, item) {
      let that = this
      that[action + 'Action'](item)
    },
    infoAction(item) {
      this.$router.push({path: `/admin/de-user/info/${item.id}`})
    },
    editAction(item) {
      this.$router.push({path: `/admin/de-user/edit/${item.id}`})
    },
    handleAdd() {
      this.$router.push({path: '/admin/de-user/add'})
    },
    onUploadSelected() {
      let that = this
      let selections = that.$refs.table.selection
      if (selections.length < 1) {
        that.$message.error('请至少选择一项')
        return
      }
      let ids = []
      for (let i in selections) {
        ids.push(selections[i].id)
      }
      that.loading = true
      deUserRepaymentPost(ids.join(',')).then(() => {
        that.loading = false
        that.$message.success('操作成功')
        that.$refs.table.loadData()
      }).catch(() => {
        that.loading = false
      })
    },
    onUploadAll() {
      let that = this
      that.loading = true
      deUserRepaymentPostAll().then(() => {
        that.loading = false
        that.$message.success('操作成功')
        that.$refs.table.loadData()
      }).catch(() => {
        that.loading = false
      })
    },
    transferInfoAction(row) {
      let that = this
      that.loading = true
      seeTransferInfo(row.id).then(res => {
        let years = Object.keys(res)
        let sum_index = years.findIndex(item => {
          return item == 'sum'
        })
        if (sum_index >= 0) {
          years.splice(sum_index, 1)
        }
        if (years.length > 0) {
          let transfer_data = res
          for (let year of years) {
            let list = []
            for (let date in transfer_data[year].data) {
              list.push({
                date: date,
                money: transfer_data[year].data[date]
              })
            }
            transfer_data[year].list = list
          }
          that.transfer_data = transfer_data
          that.transfer_years = years
          that.transfer_year = years[0]
          that.transfer_dialog_visible = true
        }
        that.loading = false
      }).catch(() => {
        that.loading = false
      })
    },
    transferEditAction(item) {
      this.$router.push({path: '/admin/de-user-examine/edit/' + item.id})
    },
    transferUploadAction(item) {
      let that = this
      that.loading = true
      deUserRepaymentPost(item.id).then(() => {
        that.loading = false
        that.$message.success('操作成功')
        that.$refs.table.loadData()
      }).catch(() => {
        that.loading = false
      })
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
